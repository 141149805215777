import React from 'react';
//gatsby
import { Link, graphql } from 'gatsby';
//seo
import Seo from 'gatsby-plugin-wpgraphql-seo';
//styles
import styled from 'styled-components';
import { rem } from 'polished';
import vars from 'components/styles/varss';
//components
import Layout from 'components/ui/layout';
import Container from 'components/ui/container';
import Heading from 'components/ui/heading';
import Icon from 'components/ui/icon';
import Small from 'components/resources/small';
//grid
import { Row, Col } from 'styled-bootstrap-grid';
//parser
import parse from "html-react-parser";

//styled
const CaseStudyArchiveWrap = styled.div`
  position: relative;
  padding-top: ${rem('100px')};
`;

const CaseStudyNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: ${rem('48px')};
    height: ${rem('48px')};
    margin-left: ${rem('10px')};
    margin-right: ${rem('10px')};
    border-radius: 50%;
    color: #fff;
    background-color: ${vars.colors.purple};
    &:hover {
      background-color: #6F54F9;
      color: #fff;
    }
    &:not([href]):not([tabindex]) {
      color: ${vars.colors.grey4};
      background-color: ${vars.colors.grey1};
      &:hover {
        color: ${vars.colors.grey4};
      }
    }
  }
`;

const CaseStudyCTA = styled.div`
  position: relative;
  margin-top: ${rem('100px')};
`;

const CaseStudyArchive = ({
  data,
  pageContext: {
    nextPagePath,
    previousPagePath
  } }) => {

  const caseStudies = data.allWpCaseStudy.nodes;
  const postsPerPage = data.wp.readingSettings.postsPerPage;

  if (!caseStudies.length) {
    return (
      <Layout isResources={true}>
        <Seo
          title="All Case Study - Refersion"
          postSchema={JSON.parse(
            data.wp.seo.contentTypes.caseStudy.schema.raw
          )}
        />
        <p>No Case Study found.</p>
      </Layout>
    )
  }

  return (
    <Layout isResources={true}>
      <Seo
        title="All Case Study - Refersion"
        postSchema={JSON.parse(
          data.wp.seo.contentTypes.caseStudy.schema.raw
        )}
      />
      <CaseStudyArchiveWrap>
        <Container>
          <Heading as="h1" marginBottom="60px">All Case Studies</Heading>
          <Row>
            {caseStudies.map((study) => (
              <Col col xs={12} sm={6} md={4} key={study.id}>
                <Small post={study} />
              </Col>
            ))}
          </Row>
          {caseStudies.length > postsPerPage ? (
            <CaseStudyNav>
              <Link to={previousPagePath}><Icon icon="caret-left" size={12} /></Link>
              <Link to={nextPagePath}><Icon icon="caret-right" size={12} /></Link>
            </CaseStudyNav>
          ) : null}
        </Container>
        <CaseStudyCTA className="wp-front">
          {!!data.wp.blogCtas.caseStudiesCTA && (
            parse(data.wp.blogCtas.caseStudiesCTA)
          )}
        </CaseStudyCTA>
      </CaseStudyArchiveWrap>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($offset: Int!, $postsPerPage: Int!) {
    wp {
      readingSettings {
        postsPerPage
      }
      blogCtas {
        caseStudiesCTA
      }
      seo {
        contentTypes {
          caseStudy {
            schema {
              raw
            }
          }
        }
      }
    }
    allWpCaseStudy( limit: $postsPerPage, skip: $offset, sort: {fields: date, order: DESC}, filter: {status: {eq: "publish"}}) {
      nodes {
        id
        title
        uri
        excerpt
        nodeType
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 460, quality: 100)
              }
            }
          }
        }
        csMeta {
          logo
          quoteName
          quoteRole
          quoteText
        }
      }
    }
  }
`

export default CaseStudyArchive;